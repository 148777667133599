import React from 'react';
import styled from 'styled-components';

const Container = ({ children, ...restProps }) => {
  return (
    <List className="blog-list infin" {...restProps}>
      {children}
    </List>
  );
};

const List = styled.ul`
  padding-top: 32px;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 0;
  flex-shrink: 1;
  justify-content: center;
`;

export default Container;
