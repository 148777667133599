import React from 'react';
import { graphql } from 'gatsby';
import Layout, { Content } from '../components/Layout';
import formatSlug from '../lib/slugFormatter';
import HeroHeader from '../components/heroheader';
import {
  Container as BlogContainer,
  Entry as BlogEntry,
  TopicButton,
  TopicList,
} from '../components/Blog';

export const query = graphql`
  query BlogsQuery {
    allStrapiBlogs (sort: {fields: created_at, order: DESC}) {
      edges {
        node {
          strapiId
          Title
          Intro
          Content
          Category {
            id
            Title
          }
          Key_Visual {
            publicURL
          }
        }
      }
    }
    allStrapiBlogCategories {
      edges {
        node {
          strapiId
          Title
        }
      }
    }
  }
`;

const Blogs = ({ data, pageContext, location }) => {
  const blogs = data.allStrapiBlogs.edges
    .map(elem => elem.node)
    .filter(blog => blog.Category)
    .sort((a, b) => (a.created_at < b.created_at ? -1 : 1));

  const blogCategories = data.allStrapiBlogCategories.edges.map(
    elem => elem.node
  );

  const navigationData = [];
  blogCategories.forEach(category => {
    navigationData.push({
      title: category.Title,
      to: formatSlug(['blog', category.Title]),
    });
  });
  return (
    <Layout title={'BUNAC Blogs'}>
      <HeroHeader
        title="BUNAC Blogs"
        backgroundUrl="/images/blog.jpg"
        fullWidth
      />
      <Content style={{ marginLeft: '0', margin: '0 auto' }}>
        <h3>Filter topics...</h3>
        <TopicList>
          {navigationData.map(category => (
            <TopicButton key={category.to} to={category.to}>
              {category.title}
            </TopicButton>
          ))}
        </TopicList>
        <BlogContainer>
          {blogs.map(blog => (
            <BlogEntry blog={blog} key={blog.strapiId} />
          ))}
        </BlogContainer>
      </Content>
    </Layout>
  );
};

export default Blogs;
