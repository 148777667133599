import styled from 'styled-components';
import { Link as _Link } from 'gatsby';

const TopicButton = styled(_Link)`
  color: #fff !important;
  background-color: #0094a5;
  text-decoration: none;
  padding: 8px;
  margin: 0 8px 8px 0;
  &:hover {
    background-color: #fb607f;
  }
`;

export default TopicButton;
