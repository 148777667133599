import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import formatSlug from '../../lib/slugFormatter';
import stripHtml from '../../lib/stripHtml';

const Entry = ({ blog }) => {
  const thumbnail = blog.Key_Visual ? blog.Key_Visual.publicURL : false;
  const link = formatSlug([
    'blog',
    blog.Category ? blog.Category.Title : 'other',
    blog.Title,
  ]);
  const categoryLink = formatSlug([
    'blog',
    blog.Category ? blog.Category.Title : 'other',
  ]);

  const introText = blog.Intro.trim()
    ? blog.Intro
    : `${stripHtml(blog.Content).substr(0, 130)}\u2026`;

  return (
    <Item className={`item ${thumbnail ? 'has-img' : ''}`}>
      {thumbnail && (
        <Figure className="media">
          <Link style={{ display: 'block' }} to={link || '/'}>
            <Thumbnail src={thumbnail} alt={blog.Title} />
          </Link>
        </Figure>
      )}
      <Description className="desc">
        <ContentLink to={link || '/'}>
          <h3>{blog.Title}</h3>
          <Content>
            <p>{introText}</p>
          </Content>
        </ContentLink>
        <Meta>
          {!!blog.Category && (
            <Link
              style={{ display: 'block', width: '100%' }}
              to={categoryLink || ''}
            >
              {blog.Category.Title}
            </Link>
          )}
          <Date>{blog.created_at}</Date>
        </Meta>
      </Description>
    </Item>
  );
};

const Item = styled.li`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex: 400px;
  flex-grow: 0;
  padding: 0;
  margin: 0 16px 16px 0 !important;
  line-height: 1.4em;
  font-weight: 300;

  /* RESET container.css stylesheets */
  /* TODO: REMOVE BLOCK */
  padding-left: 0px !important;
  &:before {
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    display: none;
  }

  &.has-img {
    flex: 700px;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    margin-right: 0;
  }
  @media screen and (min-width: 768px) {
    &:nth-child(even) {
      > div {
        order: 0;
      }
      figure {
        order: 1;
      }
    }
  }
`;

const Description = styled.div`
  padding: 16px;
  align-self: center;
  display: flex;
  flex-shrink: 1;
  flex: 400px;
  flex-grow: 0;
  background-color: #f9f9f9;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.15);

  a {
    text-decoration: none;
    h3,
    p {
      text-decoration: none;
      color: #222;
    }
  }

  @media screen and (max-width: 767px) {
    flex: auto;
  }
`;

const ContentLink = styled(Link)`
  display: block;
  width: 100%;
`;

const Content = styled.div``;

const Thumbnail = styled.img`
  width: 100%;
`;

const Meta = styled.div`
  padding-top: 16px;
  border-top: 1px solid #ccc;
`;

const Date = styled.span`
  display: block;
  width: 100%;
  color: #777;
`;

const Figure = styled.figure`
  display: flex;
  flex: 450px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 767px) {
    flex: auto;
  }
`;

export default Entry;
