import React from 'react';
import styled from 'styled-components';

const TopicList = ({ children, ...restProps }) => {
  return <List {...restProps}>{children}</List>;
};

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export default TopicList;
